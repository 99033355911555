import React, { useContext } from "react";
import ThemeContext from "../../../../contexts/themeContext";
import Breadcrumb, { BreadcrumbItem } from '../../../../components/bootstrap/Breadcrumb';
import Icon from "../../../../components/icon/Icon";
import config from "../../../../config";

const HeaderBreadcrumb = () => {

    const { breadcrumb } = useContext(ThemeContext);

    return (
        <Breadcrumb list={null} isToHome={false} divider={<Icon icon='ArrowRight' size='lg' />} autoActive>
            <BreadcrumbItem divider={<Icon icon='Home' size='lg' color={null} style={{ color: '#8F959B' }} />} />
            {breadcrumb?.length > 0 ? (
                breadcrumb?.map((item, index) => (
                    <BreadcrumbItem key={`breadcrumb-${item.id}-${index}`} to={item.path} isActive={breadcrumb?.length === index - 1}>
                        <span className={(breadcrumb?.length || 0) === index + 1 ? 'fw-bold text-dark' : 'text-dark-50 fw-light'}>{item?.text || ''}</span>
                    </BreadcrumbItem>
                ))
            ) : (
                <BreadcrumbItem to={window.location.pathname}>
                    <span className='fw-bold'>{document.getElementsByTagName('TITLE')[0]?.text?.replace('| ' + config?.title, '')}</span>
                </BreadcrumbItem>
            )}
        </Breadcrumb>
    )
}

export default HeaderBreadcrumb;