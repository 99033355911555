import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderRight from './CommonHeaderRight';
import { observer } from 'mobx-react';
import HeaderBreadcrumb from './components/Breadcrumb';

const DashboardHeader = () => {

	return (
		<>
			<Header>
				<HeaderLeft className={'text-truncate m-0 p-0'}>
					<HeaderBreadcrumb />					
				</HeaderLeft>
				<CommonHeaderRight />
			</Header>
			{/* {browserName.includes('Safari') && (
				<Alert icon='infoOutline' className={'mx-5'} rounded={0}>
					Safari 브라우저에서는 푸시알림이 지원되지 않습니다. Chrome, Edge 브라우저를 이용해주세요
				</Alert>
			)} */}
		</>
	);
};

export default DashboardHeader;
