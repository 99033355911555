import React, { Suspense, useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';

import { configure } from 'mobx';

import ThemeContext from './contexts/themeContext';
import Aside from './layout/Aside/Aside';
import Wrapper from './layout/Wrapper/Wrapper';
import Portal from './layout/Portal/Portal';

import { adminMenu, authMenu, systemAdminMenu, userMenu } from './menu';
import useDarkMode from './hooks/useDarkMode';
import COLORS from './common/data/enumColors';
import steps, { styles } from './steps';
import { Provider, rootStore, useMst } from './models';
import { getItem, setItem } from './lib/localstorage';
import { Configs } from './models/Configs';
import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';
import Toasts, { Toast, ToastContainer } from './components/bootstrap/Toasts';
import { getOS } from '../src/helpers/helpers';
import { LoaderProvider } from './components/Loader/LoaderProvider';
import axios from 'axios';

import firebaseInit from './firebaseInit';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import { getMenuWithActions, getMenuWithActionsUser, isDateInRange, replaceMessageClear } from './lib/Util';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

import CompanyService from './services/CompanyService';

import {
	useChannelIOApi,
  } from 'react-channel-plugin';

import './setupDayjs';
import Button from './components/bootstrap/Button';

configure({ enforceActions: 'always' });

const match = window?.location?.href.match(/^(?:https?:\/\/)?([^/.]+)\./i);
const subUrl = match?.length > 0 ? match[1] : null;
const userType = ['staff'].indexOf(subUrl) > -1 ? 'SYSTEM' : ['dayoff-ops'].indexOf(subUrl) > -1 ? 'ADMIN' : 'USER';

const App = () => {
	const location = useLocation();
	const navigate = useNavigate();
	// const { user, company } = useMst();
	const { addToast } = useToasts(); // addToast 가져오기
	const { t } = useTranslation(['translation', 'menu']);

	const { updateUser } = useChannelIOApi();

	useEffect(() => {
		console.log(
			`%c\n
			 __  __      ____       __         __         _____      
			/\\ \\/\\ \\    /\\  _\`\\    /\\ \\       /\\ \\       /\\  __\`\\    
			\\ \\ \\_\\ \\   \\ \\ \\L\\_\\  \\ \\ \\      \\ \\ \\      \\ \\ \\/\\ \\   
			 \\ \\  _  \\   \\ \\  _\\L   \\ \\ \\  __  \\ \\ \\  __  \\ \\ \\ \\ \\  
			  \\ \\ \\ \\ \\   \\ \\ \\L\\ \\  \\ \\ \\L\\ \\  \\ \\ \\L\\ \\  \\ \\ \\_\\ \\ 
			   \\ \\_\\ \\_\\   \\ \\____/   \\ \\____/   \\ \\____/   \\ \\_____\\
			    \\/_/\\/_/    \\/___/     \\/___/     \\/___/     \\/_____/
		`,
			'color:dark;line-height:18px'
		);

		setItem('userType', userType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	const { fullScreenStatus, setFullScreenStatus, lang, setBreadcrumb, setMenu } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [authMenu.login.path, authMenu.sendMail.path, authMenu.VerifyEmail.path, authMenu.page404.path, authMenu.signUp.path, authMenu.PasswdReset.path, 'auth/'];

	useEffect(() => { 
		setBreadcrumb([]);
		// console.log('location.pathname', location.pathname);
		if (withOutAsidePages.findIndex((v) => '/' + v === location.pathname) === -1) {
			rootStore.user.authMe().then((data) => {
				// console.log('authMe', data);
				const accessToken = getItem('access-token');
				const refreshToken = getItem('refresh-token');
				// console.log('accessToken', accessToken, refreshToken);

				if (!data?.id) {
					if(accessToken && refreshToken) {
						alert('세션이 종료되어 로그아웃 됩니다.');
					}
					setItem('access-token', '');
					setItem('refresh-token', '');

					// query 값이 있다면 들고있기
					const query = window.location.pathname + window.location.search;
					window.location.replace('/' + authMenu.login.path + (query ? `?redirect=${query}` : ''));
					// navigate('/' + authMenu.login.path + (query ? `?redirect=${query}` : ''));
				} else {
					if (data?.id > 0/*  && rootStore.user.me.currentCompanyId > 0 */) {
						// console.log('select company')
						// rootStore.company.selectCompany().then( res => {
						// 	// console.log('selectCompany res >> ', res)
						// 	// setMenu						
						// 	const refreshMenu = () => {
						// 		let menus = [];
						// 		switch(rootStore.user.me.type) {
						// 			case 'SYSTEM':
						// 				menus = systemAdminMenu;
						// 				break;
						// 			case 'ADMIN':
						// 				menus = getMenuWithActions(adminMenu, rootStore.user.me);
						// 				break;
						// 			case 'USER':
						// 				// const checkUserRestInfos = async () => {
						// 				// 	// 입사1년미만자 인가?
						// 				// 	if(dayjs(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate).add(1, 'year').isAfter(dayjs())) {
						// 				// 		console.log('입사1년미만자 인가?');
						// 				// 		CompanyService.createRestTarget(rootStore.user.me.company.code, {
						// 				// 			year: dayjs().year(),
						// 				// 			today: dayjs().format('YYYY-MM-DD'),
						// 				// 			option: 'new1Yr',
						// 				// 			selectUsers: [{id: rootStore.company.get.id}],
						// 				// 			isUse: true,
						// 				// 		}).then(res => {
						// 				// 			if(res?.data?.length > 0) {
						// 				// 				rootStore.company.selectCompany();
						// 				// 			}
						// 				// 		});
						// 				// 	}
						// 				// 	// 연차옵션이 입사일자 및 연차자동생성 옵션 설정이 되어있는 경우
						// 				// 	if (rootStore?.company.get?.workPlace?.restdaySetting?.autoGenVacReq === 'A010' && rootStore?.company.get?.workPlace?.restdaySetting?.annLeaveCrit === 'A010') {
						// 				// 		console.log('연차자동생성 옵션 설정이 되어있는 경우 확인 절차');

						// 				// 		const isBeforeTodayMonthDay = (joinDate) => {
						// 				// 			const today = dayjs();
						// 				// 			const joinDateDayMonth = dayjs(joinDate).set('year', today.year());
												  
						// 				// 			// joinDate의 월일이 오늘의 월일자보다 이전인 경우
						// 				// 			return joinDateDayMonth.isBefore(today, 'day');
						// 				// 		  };

						// 				// 		  const isAfterTodayMonthDay = (joinDate) => {
						// 				// 			const today = dayjs();
						// 				// 			const joinDateDayMonth = dayjs(joinDate).set('year', today.year());
												  
						// 				// 			// joinDate의 월일이 오늘의 월일자보다 이전인 경우
						// 				// 			return joinDateDayMonth.isAfter(today, 'day');
						// 				// 		  };

						// 				// 		//   console.log('>>>', isBeforeTodayMonthDay(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate))

						// 				// 		// 생성된 연차가가 없는경우
						// 				// 		if(rootStore?.user.me?.userRestInfos.length === 0) {
						// 				// 			console.log('생성된 연차가가 없는경우');
						// 				// 			CompanyService.createRestTarget(rootStore.user.me.company.code, {
						// 				// 				year: dayjs().year(),
						// 				// 				today: dayjs().format('YYYY-MM-DD'),
						// 				// 				option: 'dayoff',
						// 				// 				selectUsers: [{id: rootStore.company.get.id}],
						// 				// 				isUse: true,
						// 				// 			}).then(res => {
						// 				// 				if(res?.data?.length > 0) {
						// 				// 					rootStore.company.selectCompany();
						// 				// 				}
						// 				// 			});
						// 				// 		} else {
						// 				// 			// dayjs today 가 userRestInfos 에 type == 'YEAR" 이고 startDate <= today <= endDate 인경우
						// 				// 			const restInfo = rootStore?.user.me?.userRestInfos?.find(v => v.type === 'YEAR' && v.startDate <= dayjs().format('YYYY-MM-DD') && v.endDate >= dayjs().format('YYYY-MM-DD'));
						// 				// 			console.log('restInfo >>', restInfo);
						// 				// 			// joinDate 의 year 가 현재 year 보다 작은경우
						// 				// 			if(!restInfo && isAfterTodayMonthDay(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate)) {
						// 				// 				console.log('연차자동생성 대상자');
						// 				// 				CompanyService.createRestTarget(rootStore.user.me.company.code, {
						// 				// 					year: dayjs().year(),
						// 				// 					today: dayjs().format('YYYY-MM-DD'),
						// 				// 					option: 'dayoff',
						// 				// 					selectUsers: [{id: rootStore.company.get.id}],
						// 				// 					isUse: true,
						// 				// 				}).then(res => {
						// 				// 					if(res?.data?.length > 0) {
						// 				// 						rootStore.company.selectCompany();
						// 				// 					}
						// 				// 				});
						// 				// 			}
						// 				// 		}
								
						// 				// 		// // joinDate(YYYY-MM-DD)의 월일값이 현재일자(YYYY-MM-DD)의 월일값보다 작은경우

						// 				// 		// if (dayjs(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate).isBefore(dayjs()) || rootStore?.user.me?.userRestInfos.length === 0 || rootStore?.user.me?.userRestInfos?.findIndex(v => v.type === 'YEAR' && v.startDate <= dayjs().format('YYYY-MM-DD') && v.endDate >= dayjs().format('YYYY-MM-DD') ) === -1) {
						// 				// 		// 	console.log('연차자동생성 대상자');
						// 				// 		// // 입사년도 기준 확인후 생성 요청
						// 				// 		// // console.log('입사년도 기준 확인후 생성 요청');
						// 				// 		// // console.log('company.get >>', company.get)
						// 				// 		// // 입사일자 기준이고 연차자동생성 옵션 설정이 되어있는 경우
												
						// 				// 		// 	// autoGenVacReq = A010
						// 				// 		// 	// annLeaveCrit = A010
						// 				// 		// 	// createRestTargetUser

						// 				// 		// 	// 입사일자 생성 대상인가?

						// 				// 		// 	// const res = await CompanyService.createRestTarget(company.get?.code, {
						// 				// 		// 	// 	option: 'dayoff',
						// 				// 		// 	// 	selectUsers: [company.get],
						// 				// 		// 	// 	today: dayjs().format('YYYY-MM-DD'),
						// 				// 		// 	// 	type: 'dayoffMange',
						// 				// 		// 	// 	year: dayjs().format('YYYY'),
						// 				// 		// 	// 	isUse: true,
						// 				// 		// 	// });
						// 				// 		// 	// // console.log('createRestTarget >>', res?.data);
						// 				// 		// 	// if (res?.data[0]?.userRestInfos?.length > 0) {
						// 				// 		// 	// 	await company.selectCompany();
						// 				// 		// 	// }
						// 				// 		// }
						// 				// 	}
						// 				// };
						// 				// checkUserRestInfos();
						// 				// // 입사일자가 1년이내일경우 월차 갱신
						// 				menus = getMenuWithActionsUser(userMenu, {
						// 					...rootStore.user.me,
						// 					isApproval: res?.isApproval || false,
						// 				});
						// 				break;
						// 			default:
						// 				menus = [];
						// 				break;
						// 		}
						// 		return menus;
						// 	}
						// 	setMenu(refreshMenu);							
						// })
						const refreshMenu = () => {
							let menus = [];
							switch(userType) {
								case 'SYSTEM':
									menus = systemAdminMenu;
									break;
								case 'ADMIN':
									// console.log('ADMIN', adminMenu, data);
									menus = getMenuWithActions(adminMenu, data);
									break;
								case 'USER':									
									menus = getMenuWithActionsUser(userMenu, {
										...data,
										// isApproval: res?.isApproval || false,
									});
									break;
								default:
									menus = [];
									break;
							}
							// console.log('menus', adminMenu, menus);
							return menus;
						}
						setMenu(refreshMenu);							
						rootStore.notifiction.getRefresh(data?.company?.code);
					} else {
						if(userType === 'SYSTEM') {
							setMenu(systemAdminMenu);
						}
					}
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	/**
	 * 최초 실행 시, 필요한 부분이 있다면 추가
	 */
	useEffect(() => { 		
		if (withOutAsidePages.findIndex((v) => '/' + v === location.pathname) === -1) {
			const refreshActions = () => {
				// console.log('refreshActions', userType);
				switch(userType) {
					case 'SYSTEM':
						//
						break;
					case 'ADMIN':
						//
						break;
					case 'USER':
						const checkUserRestInfos = async () => {
							/**
							 * autoGenVacReq = 연차 신청시 자동생성 여부
							 * annLeaveCrit = 연차기준 A010: 입사일자, A015: 회계연도
							 * carryoverLess1Yr = 입사1년미만자 휴가이월여부 A010: 휴가이월, A015: 휴가이월안함
							*/
							// console.log('-', rootStore.user.me);
							// 입사1년미만자 인가?
							if((dayjs(rootStore.user.me?.applyJoinDate || rootStore.user.me?.joinDate).add(1, 'year').isAfter(dayjs())) && rootStore.user.me?.retireDate === null) {
								// console.log('입사1년미만자 인가?');
								CompanyService.createRestTarget(rootStore.user.me.company.code, {
									year: dayjs().year(),
									today: dayjs().format('YYYY-MM-DD'),
									option: 'new1Yr',
									selectUsers: [{id: rootStore.user.me.id}],
									isUse: true,
								}).then(res => {
									if(res?.data?.length > 0) {
										rootStore.user.authMe();
										// rootStore.company.authMe();
										// rootStore.company.selectCompany();
									}
								});
							}

							// 연차옵션이 입사일자 및 연차자동생성 옵션 설정이 되어있는 경우
							if (rootStore.user.me?.workPlace?.restdaySetting?.autoGenVacReq === 'A010' && rootStore.user.me?.workPlace?.restdaySetting?.annLeaveCrit === 'A010') {
								// console.log('연차자동생성 옵션 설정이 되어있는 경우 확인 절차');
								// console.log('a', rootStore.user.me?.userRestInfos.length, rootStore.user.me?.retireDate);

								const isBeforeTodayMonthDay = (joinDate) => {
									const today = dayjs();
									const joinDateDayMonth = dayjs(joinDate).set('year', today.year());

									// joinDate의 월일이 오늘의 월일자보다 이전인 경우
									return joinDateDayMonth.isBefore(today, 'day');
								};

								const isAfterTodayMonthDay = (joinDate) => {
									const today = dayjs();
									const joinDateDayMonth = dayjs(joinDate).set('year', today.year());

									// console	.log('>>> joinDateDayMonth', joinDateDayMonth.format('YYYY-MM-DD'), today.format('YYYY-MM-DD'));

									// joinDate의 월일이 오늘의 월일자보다 이전인 경우
									return joinDateDayMonth.isAfter(today, 'day');
								};

								//   console.log('>>>', isBeforeTodayMonthDay(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate))

								// 생성된 연차가가 없는경우
								if (rootStore.user.me?.userRestInfos.length === 0 && rootStore.user.me?.retireDate === '') {
									// console.log('b');
									// console.log('생성된 연차가가 없는경우');
									CompanyService.createRestTarget(rootStore.user.me.company.code, {
										year: dayjs().year(),
										today: dayjs().format('YYYY-MM-DD'),
										option: 'dayoff',
										selectUsers: [{ id: rootStore.user.me.id }],
										isUse: true,
									}).then(res => {
										if (res?.data?.length > 0) {
											rootStore?.user.authMe();
										}
									});
								} else {
									// dayjs today 가 userRestInfos 에 type == 'YEAR" 이고 startDate <= today <= endDate 인경우
									const restInfo = rootStore.user.me?.userRestInfos?.find(v => v.type === 'YEAR' && v.startDate <= dayjs().format('YYYY-MM-DD') && v.endDate >= dayjs().format('YYYY-MM-DD'));

									// today MM-DD 가 joinDate MM-DD 보다 이전인경우
									// console.log('c', restInfo, isBeforeTodayMonthDay(rootStore.user.me?.applyJoinDate || rootStore.user.me?.joinDate));

									// console.log(">>>", rootStore.user.me?.userRestInfos[rootStore.user.me?.userRestInfos.length - 1]?.endDate, dayjs().format('YYYY-MM-DD'));

									// console.log('c', restInfo, isBeforeTodayMonthDay(rootStore.user.me?.applyJoinDate || rootStore.user.me?.joinDate));
									// console.log('restInfo >>', restInfo);
									// joinDate 의 year 가 현재 year 보다 작은경우
									if (!restInfo && isAfterTodayMonthDay(rootStore.user.me?.userRestInfos[rootStore.user.me?.userRestInfos.length - 1]?.endDate) && rootStore.user.me?.retireDate === '') {
										// console.log('c2');
										// console.log('연차자동생성 대상자');
										CompanyService.createRestTarget(rootStore.user.me.company.code, {
											year: dayjs().year(),
											today: dayjs().format('YYYY-MM-DD'),
											option: 'dayoff',
											selectUsers: [{ id: rootStore.user.me.id }],
											isUse: true,
										}).then(res => {
											if (res?.data?.length > 0) {
												rootStore?.user.authMe();
											}
										});
									}
								}

								// // joinDate(YYYY-MM-DD)의 월일값이 현재일자(YYYY-MM-DD)의 월일값보다 작은경우

								// if (dayjs(rootStore?.company?.get?.applyJoinDate || rootStore?.company?.get?.joinDate).isBefore(dayjs()) || rootStore?.user.me?.userRestInfos.length === 0 || rootStore?.user.me?.userRestInfos?.findIndex(v => v.type === 'YEAR' && v.startDate <= dayjs().format('YYYY-MM-DD') && v.endDate >= dayjs().format('YYYY-MM-DD') ) === -1) {
								// 	console.log('연차자동생성 대상자');
								// // 입사년도 기준 확인후 생성 요청
								// // console.log('입사년도 기준 확인후 생성 요청');
								// // console.log('company.get >>', company.get)
								// // 입사일자 기준이고 연차자동생성 옵션 설정이 되어있는 경우

								// 	// autoGenVacReq = A010
								// 	// annLeaveCrit = A010
								// 	// createRestTargetUser

								// 	// 입사일자 생성 대상인가?

								// 	// const res = await CompanyService.createRestTarget(company.get?.code, {
								// 	// 	option: 'dayoff',
								// 	// 	selectUsers: [company.get],
								// 	// 	today: dayjs().format('YYYY-MM-DD'),
								// 	// 	type: 'dayoffMange',
								// 	// 	year: dayjs().format('YYYY'),
								// 	// 	isUse: true,
								// 	// });
								// 	// // console.log('createRestTarget >>', res?.data);
								// 	// if (res?.data[0]?.userRestInfos?.length > 0) {
								// 	// 	await company.selectCompany();
								// 	// }
								// } 
							}
							// console.log('carryoverLess1Yr 입사1년미만자 휴가이월여부 A010', rootStore?.user.me);
							// carryoverLess1Yr 입사1년미만자 휴가이월여부 A010, 연차촉진대상이 아닌경우
							if(rootStore.user.me?.workPlace?.restdaySetting?.carryoverLess1Yr === 'A010' && rootStore?.user?.me?.applyVacPromoScheme === false) {
								// console.log('d');
								// console.log('carryoverLess1Yr 입사1년미만자 휴가이월여부 A010', rootStore?.user.me?.userRestInfos);
								const beforeMonth = rootStore.user.me?.userRestInfos?.find(v => v.type === 'MONTH' && v.year === dayjs().year() - 1 && dayjs(v.endDate).isBefore(dayjs()));
								const thisYears = isDateInRange(rootStore.user.me.userRestInfos, dayjs(), dayjs());
								// console.log('beforeMonth', beforeMonth, thisYears);
								if(beforeMonth?.id > 0 && beforeMonth?.remainingDays > 0) {
									// console.log('d2');
									// 이월할 잔여수 있으면 이월 API 호출하자~~~ previousYearUnusedDays		
									// 이월연차개수가 서로 상이하다면? 이월 API 호출										
									if(thisYears[0]?.id > 0 && thisYears[0]?.previousYearUnusedDays !== beforeMonth?.remainingDays) {
										// console.log('3');
										// console.log('이월할 잔여수 있으면 이월 API 호출하자~~~');
										CompanyService.forwardUserRestInfo(rootStore.user.me.company.code); 
									}
								}
							}
						};
						checkUserRestInfos();					
						updateUser({
							profile: {
								name: rootStore.user.me.name,
								license: rootStore.user.me.license,
								email: rootStore.user.me.email,
								mobileNumber: rootStore.user.me.phone,
							}
						})							
						break;
					default:
						//
						break;
				}							
			}
			// console.log('refreshActions');
			refreshActions();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rootStore.user.me, userType]);
	

	// FIREBASE FCM
	// const [webToken, setwebToken] = useState(getItem('webToken'));

	let messaging = null;
	if (firebase.messaging.isSupported() && !messaging) {
		messaging = firebaseInit.messaging();
	}

	const connectType = getItem('user-connect-type');
	// useEffect(() => {
	// 	// console.log('Configs', Configs.get);
	// 	// Configs.setFcmId(0);
	// 	// console.log('connectType', connectType, rootStore.user.me.id, Configs.get.uuid, Configs.get.publicIp, Configs.get.os, webToken);

	// 	if ((connectType != 'token' && rootStore.user.me.id > 0 && Configs.get.uuid && Configs.get.publicIp && Configs.get.os && webToken) /*  && Configs.get.fcmCode */) {
	// 		// console.log('sendDevice');
	// 		Configs.sendDevice();
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [Configs.get, webToken]);





	// useEffect(() => {		
	// 	if (firebase.messaging.isSupported()) {
	// 		if ((!webToken || webToken === '') && messaging) {
	// 			// getFireBaseMessaging();
	// 		}
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [webToken]);

	// const [notification, setNotification] = useState('');


	useEffect(() => {
		// const requestNotification = async () => {
		// 	try {
		// 		const permission = await Notification.requestPermission();
		// 		// console.log('permission', permission);
		// 		if (permission === 'granted') {
		// 			const token = await messaging.getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
		// 			// console.log('token', token);
		// 			setItem('webToken', token);
		// 			setwebToken(token);
		// 		}
		// 	} catch (error) {
		// 		console.error('Error getting permission or token:', error);
		// 	}
	
		// 	// if (!('Notification' in window)) {
		// 	// 	setNotification(null);
		// 	// } else if (Notification.permission !== 'granted') {
		// 	// 	Notification.requestPermission().then(async (permission) => {
		// 	// 		// console.log('permission', permission);
		// 	// 		if (permission === 'granted') {
		// 	// 			if (isSupported()) {
		// 	// 				const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
		// 	// 				console.log('token', token);
		// 	// 				setItem('webToken', token);
		// 	// 				setwebToken(token);
		// 	// 			}
		// 	// 			// setNotification(true);
	
		// 	// 		}
		// 	// 		//  else {
		// 	// 		// 	setNotification(false);
		// 	// 		// }
		// 	// 	});
		// 	// } else {
		// 	// 	// setNotification(true);
		// 	// }
		// };
		// const handleTokenRefresh = async () => {
		// 	try {
		// 		const token = await messaging.getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY });
		// 		// console.log('New FCM Token:', token);
		// 		setItem('webToken', token);
		// 		setwebToken(token);
		// 	} catch (error) {
		// 		console.error('Error getting new token:', error);
		// 	}
		// };
		const getIpOs = () => {
			Promise.all([
				// 첫 페이지 진입 여러작업 추가시 요기에
				getCurrentBrowserFingerPrint()
					.then((fingerprint) => {
						// console.log('fingerprint', dayjs().format('YYYY.MM.DD HH:mm:ss'), fingerprint);
						return fingerprint;
					})
					.then((fingerprint) => {
						// console.log('fingerprint', fingerprint);
						Configs.setUuid(fingerprint);
						Configs.setOs(getOS());
						try {
							axios.get('https://api.ipify.org?format=json').then((res) => {
								if (res?.data?.ip) {
									// console.log('ipify res', dayjs().format('YYYY.MM.DD HH:mm:ss'), res.data.ip);
									Configs.setPublicIp(res.data.ip);
								}
							});
						} catch (e) {
							// console.log('ipify error', e);
						}
						// axios.get('//geolocation-db.com/json/').then((res) => {
						// 	console.log('geolocation-db.com res', res.data)

						// 	const config = {
						// 		...res.data,
						// 		fingerprint,
						// 		os: getOS()
						// 	}
						// 	console.log('default config ', config);
						// 	// Configs.setUuid(fingerprint);
						// 	// Configs.setPublicIp(res.data.IPv4);
						// 	// Configs.setOs(getOS())
						// }).catch((err) => {
						// 	console.log('geolocation-db.com err', err)
						// });
					}),
				// requestNotification(),
			]);
		};
		getIpOs();
		// requestNotification();
		// const unsubscribe = messaging.onTokenRefresh(messaging, handleTokenRefresh);

		if (firebase.messaging.isSupported() && messaging) {

			if ('serviceWorker' in navigator) {
				navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
					console.log('서비스 워커 등록 : ', registration?.active?.state);
				});
			}
			// const requestPermission = async () => {
			// 	console.log('알림 권한 요청');
			// 	Notification.requestPermission().then(async (permission) => {
			// 		console.log('알림 권한:', permission);
			// 		try {
			// 			if (permission === 'granted') {
			// 				console.log('알림 권한 허용됨');
			// 				const token = await messaging.getToken(messaging, {
			// 					vapidKey: process.env.REACT_APP_VAPID_KEY,
			// 				});
			// 				//   console.log("토큰:", token);
			// 				setItem('webToken', token);
			// 				setwebToken(token);
			// 				Configs.setFcm(webToken);
			// 			} else {
			// 				console.error('알림 권한 거부됨');
			// 			}
			// 		} catch (error) {
			// 			console.error('Error getting permission or token:', error);
			// 		}
			// 	});		
			// }

			// requestPermission();
		}

		if (firebase.messaging.isSupported() && messaging) {
			// console.log('firebase.messaging.isSupported() onMessage', messaging);
			// const getFireBaseMessaging = async () => {
			// 	if (firebase?.messaging?.isSupported() && messaging) {
			// 		try {
			// 			const permission = await Notification.requestPermission();
			// 			if (permission === 'granted') {
			// 				const token = await messaging.getToken(messaging, {
			// 					vapidKey: process.env.REACT_APP_VAPID_KEY,
			// 				});
			// 				  console.log("토큰:", token);
			// 				setItem('webToken', token);
			// 				setwebToken(token);
			// 				Configs.setFcm(webToken);
			// 			}
			// 		} catch (error) {
			// 			// console.error("알림 권한 요청 중 오류:", error);
			// 		}
			// 	}
			// };
			// getFireBaseMessaging();


			messaging.onMessage((payload) => {
				console.log('포그라운드 메시지 수신 등록', payload);

				// const payloads = JSON.parse(payload?.data || '{}');
				// console.log("포그라운드 메시지 수신:", payload);
				/*
					{
						"from": "433045748133",
						"messageId": "df7e7a75-4363-4910-b412-adacafe6917a",
						"notification": {
							"title": "결재요청 알림",
							"body": "[총무부] 배다현 님이 새로운 연차 휴가를 신청했습니다. 내용을 검토해주세요."
						},
						"data": {
							"requestDepartName": "총무부",
							"body": "[{{requestDepartName}}] {{requestUserName}} 님이 새로운 연차 휴가를 신청했습니다. 내용을 검토해주세요.",
							"requestUserName": "배다현",
							"startDate": "2025-03-04(화)",
							"title": "결재요청 알림",
							"requestDate": "2025-02-26 10:47:54",
							"receiverId": "589",
							"link": "https://dayoff.insa.com:3000/leave/request/detail/78",
							"detail": ""
						},
						"fcmOptions": {
							"link": "https://dayoff.insa.com:3000/leave/request/detail/78"
						}
					}

				*/
				addToast(
					<Toasts
						icon={'InfoOutline'}
						iconColor={'dark'}
						title={t(payload.data?.title ? payload.data?.title : payload.notification.title, payload)}
						/* isDismiss */
					>
						<div className='d-flex flex-column justify-content-between align-items-center w-100'>
							{/* <div className={`border p-2 border-info`}>
								<Icon icon='EventAvailable' size='2x' color={'info'} />
							</div> */}
							<div className='flex-grow-1 px-4 text-wrap py-3' style={{ overflowWrap: 'anywhere' }}>
								{replaceMessageClear(t(payload.data?.body ? payload.data?.body : payload.notification.body, payload.data))}
							</div>
							{payload?.data?.path && <Button type='button' size='xs' onClick={() => {
								if(payload?.data?.path) {
									navigate(payload?.data?.path);
								}
								// navigate(payload.data.wwebLink);
							}} ><small>바로가기</small></Button>}
							{/* {payload?.data?.detailId && <Button type='button' size='xs' onClick={()=>{
								const cryptoUrl = Base64.encode(`${company.get.id}_${payload?.data?.detailId}`);
								window.location.href = `/e-approval/document/${cryptoUrl}`;
							}} ><small>링크</small></Button>} */}
						</div>
					</Toasts>,
					{
						// autoDismiss: true,
						// autoClose: 10000, // 10초
					}
				);

				// 알림 갯수 갱신
				rootStore.notifiction.getRefresh(rootStore.user.me.company.code);
			});

			// console.log('connectType', connectType, rootStore.user.me.id, Configs.get.uuid, Configs.get.publicIp, Configs.get.os, webToken);
			// if ((connectType != 'token' && rootStore.user.me.id > 0 && Configs.get.uuid && Configs.get.publicIp && Configs.get.os && webToken) /*  && Configs.get.fcmCode */) {
			// 	// console.log('sendDevice');
			// 	Configs.sendDevice();
			// }
		}

		const token = getItem('webToken');
		if(token) {
			Configs.setFcm(token);
		}

		return () => {
			// unsubscribe();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Provider value={rootStore}>
			{/* {rootStore.user.me.id > 0 && Notification.permission !== 'granted' && (
				<Alert rounded={0} color={'danger'} borderWidth={0} className='position-fixed w-100 d-flex justify-content-center small fw-bold text-white' style={{ zIndex: 1031 }} isDismissible>
					<div className='text-center'>
						알림 권한이 허용되지 않은 상태입니다. 알림 설정을 변경하려면 다음을 따라주세요.
						<br />
						{isChrome && '자물쇠 아이콘(🔒) 클릭 → 사이트 설정 → 알림 허용'}
						{isEdge && '자물쇠 아이콘(🔒) 클릭 → 사이트 설정 → 알림 허용'}
						{isFirefox && '자물쇠 아이콘(🔒) 클릭 → 사이트 설정 → 알림 허용'}
						{isSafari && '사파리 설정 → 웹사이트 설정 → 알림 허용'}
						{isOpera && '자물쇠 아이콘(🔒) 클릭 → 사이트 설정 → 알림 허용'}
					</div>
				</Alert>
			)} */}

			<ThemeProvider theme={theme}>
				{/* <ToastProvider components={{ ToastContainer, Toast }}> */}
				
					<TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
						<LoaderProvider>
							{/* <Suspense fallback={<div>Loading...</div>}> */}
								<div
									ref={ref}
									className='app'
									style={{
										backgroundColor: fullScreenStatus && 'var(--bs-body-bg)',
										zIndex: fullScreenStatus && 1,
										overflow: fullScreenStatus && 'scroll',
									}}>
									<Routes>
										{withOutAsidePages.map((path) => (
											<Route key={path} path={path} />
										))}
										<Route path='*' element={rootStore.user.me.id === 0 ? null : <Aside />} />
									</Routes>

									<Wrapper />
								</div>
							{/* </Suspense> */}
						</LoaderProvider>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				
				{/* </ToastProvider> */}
			</ThemeProvider>
		</Provider>
	);
};

export default App;
